import { Suspense } from "react";
import { Route, Switch } from "wouter";
import HomePage from "./pages/HomePage";
import ErrorPage404 from "./pages/404";

function App() {
  return (
    <Switch>
      <Route path="/" component={HomePage} />
      {/* Default Error Page */}
      <Route path="/:rest*" component={ErrorPage404} />
    </Switch>
  );
}

export default function SuspendedApp() {
  return (
    <Suspense fallback="">
      <App />
    </Suspense>
  );
}
