import { useTranslation } from "react-i18next";
import React, { useState } from "react";

const HeroSection = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);

  return (
    <>
      <section
        id="hero-section"
        className="flex h-screen-4/5 bg-cover bg-center transition-all transform duration-500 ease-in-out"
        style={{ backgroundImage: `url('${services[selected].img}')` }}
      >
        <h1
          className={`font-source-sans font-bold uppercase w-full max-w-screen-lg tracking-tighter transform duration-200 text-4xl md:text-5xl py-4 pl-4 md:pr-[500px] xl:pr-[600px] text-white mb-28 mt-auto mx-4 md:mx-auto ${services[selected].h1Class}`}
        >
          <span className="w-full lg:w-1/2">
            {t(`HeroSection.${services[selected].tKey}.header`)}
            <span className="block text-sm md:text-lg font-light tracking-wider font-source-serif">
              {t(`HeroSection.${services[selected].tKey}.label`)}
            </span>
          </span>
        </h1>
      </section>
      <section
        id="services-section"
        className={`mx-auto max-w-screen-lg bg-darkGray text-white rounded-sm flex flex-col transform duration-200 -translate-y-1/2 py-4 px-2 md:py-5 md:px-4`}
      >
        <div className="flex justify-between">
          {services.map(({ tKey, icon }, index) => (
            <a
              className={`w-1/3 flex flex-col cursor-pointer transform duration-150 ${
                selected !== index ? "text-gray-500" : "text-secondary"
              }`}
              key={tKey + index}
              href="valid"
              onMouseOver={() => setSelected(index)}
              onClick={(e) => e.preventDefault()}
            >
              <i className={`${icon} mx-auto text-5xl md:text-6xl`}></i>
              <span className="mx-auto font-rubik font-semibold text-base md:text-xl mt-1.5">
                {t(`HeroSection.${tKey}.label`)}
              </span>
            </a>
          ))}
        </div>
        <div className="mt-5">
          <p className="px-6 md:px-10 text-base md:text-lg">
            {t(`HeroSection.${services[selected].tKey}.text`)}
          </p>
        </div>
      </section>
    </>
  );
};

export default HeroSection;

const services = [
  {
    icon: "ri-file-edit-line",
    img: "/static/hero-img-01.jpg",
    tKey: "planning",
    h1Class: "",
  },
  {
    icon: "ri-treasure-map-line",
    img: "/static/hero-img-02.jpg",
    tKey: "consulting",
    h1Class: "",
  },
  {
    icon: "ri-earth-line",
    img: "/static/hero-img-03.jpg",
    tKey: "logistics",
    h1Class: "",
  },
];
