import { SchemaModel, StringType } from "schema-typed";

const ContactSchema = SchemaModel({
  item_name_1: StringType()
    .isRequired("name.required")
    .minLength(2, "name.tooShort"),
  item_mail_1: StringType()
    .isRequired("email.required")
    .isEmail("email.invalid"),
  item_contents: StringType()
    .isRequired("contents.required")
    .minLength(10, "contents.tooShort"),
  item_stext01: StringType()
    .minLength(15, "phone.tooShort")
    .containsNumber("phone.isNaN"),
  item_stext02: StringType().minLength(3, "company.tooShort"),
  submit_button: StringType().isOneOf(["送信"], "button.wrong"),
});

export default ContactSchema;
