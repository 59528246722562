function Button({ children, type, icon, className, ...rest }) {
  return (
    <button type={type || "button"} {...rest} className={`${className} flex transition-all transform duration-300`}>
      {icon && <i className={`${icon} mr-2 my-auto`} />}
      <span className="my-auto">{children}</span>
    </button>
  );
}

export default Button;
