import React from "react";
import HeaderMenu from "../components/HeaderMenu";
import AboutSection from "../components/page/HomePage/AboutSection";
import HeroSection from "../components/page/HomePage/HeroSection";
import ContentSection from "../components/page/HomePage/ContentSection";
import ContactSection from "../components/page/HomePage/ContactSection";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import KaishaAnnai from "../components/page/HomePage/KaishaAnnai";

const HomePage = () => {

  const { i18n} = useTranslation()

  return (
    <>
      <HeaderMenu />
      <HeroSection />
      <AboutSection />
      {i18n.language === "jp" && <KaishaAnnai />}
      <ContentSection />
      <ContactSection />
      <Footer />
    </>
  );
};

export default HomePage;
