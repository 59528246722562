import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <section
      id="about-section"
      className="mx-auto mb-24 md:mt-10 max-w-screen-lg font-rubik flex flex-wrap"
    >
      <div className="w-full sm:w-1/2 flex flex-col">
        <h2 className="w-full border-l-8 border-darkBlue text-5xl font-semibold tracking-tighter text-center sm:text-left flex">
          <span className="ml-10 md:ml-16 uppercase">
            {t(`AboutSection.title`)}
          </span>
        </h2>
        <div className="ml-3.5 pl-12 md:pl-16 uppercase text-darkBlue font-semibold text-xl tracking-wider">
          {t(`AboutSection.subtitle`)}
        </div>
        <div className="ml-3 pl-10 md:pl-16 mt-12 flex-grow flex flex-col justify-center">
          <p className="max-w-xs font-semibold text-darkGray">
            {t(`AboutSection.text`)}
          </p>
        </div>
      </div>
      <div className="w-full sm:w-1/2 flex flex-col sm:border-l-8 border-darkBlue mt-16 sm:mt-0">
        <h2 className="w-full border-l-8 sm:border-0 border-darkBlue text-5xl font-semibold tracking-tighter md:text-center flex">
          <span className="ml-10 md:ml-16 uppercase md:text-center">
            {t(`SectorsSection.title`)}
          </span>
        </h2>
        <div className="md:ml-0 pl-12 md:pl-16 uppercase text-darkBlue font-semibold text-xl tracking-wider max-w-xs">
          {t(`SectorsSection.subtitle`)}
        </div>
        <div className="ml-3 md:ml-0 pl-10 md:pl-16 mt-4">
          <p className="max-w-xs font-semibold text-darkGray">
            {t(`SectorsSection.text`)}
          </p>
        </div>
        <div className="ml-3 md:ml-0 pl-10 md:pl-16 mt-10 grid gap-4 grid-cols-1 sm:grid-cols-2">
          {sectors.map((item) => (
            <div key={item.tKey} className="flex font-semibold">
              <div className={"pl-3 " + item.css}></div>
              <div className="pl-4 py-1.5 my-auto">
                {t(`SectorsSection.sectors.${item.tKey}`)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;

const sectors = [
  {
    tKey: "robotics",
    css: "bg-rose-500",
  },
  {
    tKey: "industry",
    css: "bg-teal-500",
  },
  {
    tKey: "home",
    css: "bg-blue-600",
  },
  {
    tKey: "food",
    css: "bg-yellow-300",
  },
];
