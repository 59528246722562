import React from "react";
import { useTranslation } from "react-i18next";

const ContentSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <section
        id="import-export-section"
        className=" bg-center bg-cover h-52 md:h-96 flex flex-col justify-center sm:justify-end"
        style={{ backgroundImage: "url('/static/container-ship.png')" }}
      >
        <div className="max-w-screen-lg w-full m-auto md:h-full flex flex-col sm:justify-end md:pb-5">
          <h2 className="font-source-sans font-bold text-white text-5xl md:text-6xl text-center md:text-left uppercase whitespace-pre-line md:pl-4">
            {t(`ContentSection.header`)}
          </h2>
        </div>
      </section>
      <section className="w-full max-w-screen-lg font-rubik mx-auto my-24 flex flex-col">
        <h3 className="w-full border-0 md:border-l-8 border-darkBlue text-4xl font-semibold tracking-tight px-2 sm:px-0 text-center md:text-left">
          <span className="sm:ml-10 md:ml-16 uppercase">
            {t(`ContentSection.content.exports from`)}{" "}
            <span className="text-red-500">
              {t(`ContentSection.content.japan`)}
            </span>
          </span>
        </h3>
        <div className="sm:ml-10 md:ml-2 px-2.5 my-6 md:my-0 md:pl-16 uppercase text-darkBlue font-semibold text-lg md:text-xl tracking-wider text-center md:text-left">
          {t(`ContentSection.content.subtitle-1`)}
        </div>
        <p className="px-3 md:pl-16 md:ml-2 md:mr-6 md:mt-12 text-center md:text-left leading-7 text-darkGray">
          {t(`ContentSection.content.text-1`)}
        </p>
        <h3 className="w-full border-0 md:border-l-8 border-darkBlue text-4xl font-semibold tracking-tight px-2 sm:px-0 text-center md:text-left mt-20">
          <span className="sm:ml-10 md:ml-16 uppercase">
            {t(`ContentSection.content.imports to`)}{" "}
            <span className="text-primary">
              {t(`ContentSection.content.brazil`)}
            </span>
          </span>
        </h3>
        <div className="sm:ml-10 md:ml-2 px-2.5 my-6 md:my-0 md:pl-16 uppercase text-darkBlue font-semibold text-lg md:text-xl tracking-wider text-center md:text-left">
          {t(`ContentSection.content.subtitle-2`)}
        </div>
        <p className="px-3 md:pl-16 md:ml-2 md:mr-6 md:mt-12 text-center md:text-left leading-7 text-darkGray">
          {t(`ContentSection.content.text-2`)}
        </p>
      </section>
    </>
  );
};

export default ContentSection;
