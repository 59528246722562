import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "./commons/Button";
import ScrollIntoView from "react-scroll-into-view";
import { useLocation } from "wouter";

const HeaderMenu = () => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [innerHeight, setInnerHeight] = useState(100);

  const handleScroll = () => {
      setInnerHeight(window.innerHeight)
      setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`fixed w-screen z-40 bg-darkGray ${ scrollPosition < innerHeight ? "bg-opacity-30" : "bg-opacity-70"} transform duration-500`}>
      <WebMenu />
      <MobileMenu />
    </header>
  );
};

export default HeaderMenu;

function WebMenu() {
  const { t, i18n } = useTranslation();

  const [location, setLocation] = useLocation();

  const goToHome = () => {
    if (location !== "/") setLocation("/");
  };

  return (
    <nav className="hidden sm:flex my-2 mx-auto max-w-screen-2xl font-source-sans px-6">
      <div className="flex-grow inline-flex">
        <img
          src="/static/logo-onoda-boeki.png"
          alt="Onoda Boeki Logo"
          className="w-[52px] h-auto"
        />
        <span className={`hidden lg:inline my-auto ml-3 font-trueno text-white text-xl font-semibold `}>ONODA <span className="font-thin">BOEKI</span></span>
      </div>
      <div className="w-1/5 xl:w-1/12 my-auto inline-flex justify-center">
        <ScrollIntoView
          selector="#hero-section"
          scrollOptions={{ behavior: "smooth", block: "center" }}
          onClick={goToHome}
        >
          <Button className="text-white font-semibold hover:text-darkBlue">
            {t("HeaderMenu.home")}
          </Button>
        </ScrollIntoView>
      </div>
      <div className="w-1/5 xl:w-1/12 my-auto inline-flex justify-center">
        <ScrollIntoView
          selector="#about-section"
          scrollOptions={{ behavior: "smooth", block: "center" }}
          onClick={goToHome}
        >
          <Button className="text-white font-semibold hover:text-darkBlue">
            {t("HeaderMenu.about")}
          </Button>
        </ScrollIntoView>
      </div>
      <div className="w-1/5 xl:w-1/12 my-auto inline-flex justify-center">
        <ScrollIntoView
          selector="#import-export-section"
          scrollOptions={{ behavior: "smooth", block: "top" }}
          onClick={goToHome}
        >
          <Button className="text-white font-semibold hover:text-darkBlue">
            {t("HeaderMenu.services")}
          </Button>
        </ScrollIntoView>
      </div>
      <div className="w-1/5 xl:w-1/12 my-auto inline-flex justify-center">
        <ScrollIntoView
          selector="#contact-section"
          scrollOptions={{ behavior: "smooth", block: "top" }}
          onClick={goToHome}
        >
          <Button className="text-white font-semibold hover:text-darkBlue">
            {t("HeaderMenu.contact")}
          </Button>
        </ScrollIntoView>
      </div>
      <div className="my-auto inline-flex justify-center">
        <Button onClick={() => i18n.changeLanguage("en")} className="mr-2.5">
          <img
            src="/static/Flag_of_Great_Britain.svg"
            alt="En flag"
            className="w-6 h-auto mt-auto"
          />
        </Button>
        <Button onClick={() => i18n.changeLanguage("pt-BR")} className="mr-2.5">
          <img
            src="/static/Flag_of_Brazil.svg"
            alt="Br flag"
            className="w-6 h-auto mt-auto"
          />
        </Button>
        <Button onClick={() => i18n.changeLanguage("jp")} className="mr-2.5">
          <img
            src="/static/Flag_of_Japan.svg"
            alt="Jp flag"
            className="w-6 h-auto mt-auto"
          />
        </Button>
      </div>
    </nav>
  );
}

function MobileMenu() {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [innerHeight, setInnerHeight] = useState(100);

  const handleScroll = () => {
      setInnerHeight(window.innerHeight)
      setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const goToHome = () => {
    if (location !== "/") setLocation("/");
  };

  const { t, i18n } = useTranslation();
  return (
    <div className={`fixed flex sm:hidden w-full justify-between align-middle transform duration-500 bg-darkGray ${ scrollPosition <= innerHeight ? "bg-opacity-30" : "bg-opacity-70"} `}>
      <div className="flex-grow">
        <img
          src="/static/logo-onoda-boeki-mobile.png"
          alt="Onoda Boeki Logo"
          className="w-[55px] h-auto my-2 ml-4"
        />
      </div>
      <Button
        className="text-white pr-3.5 my-auto"
        icon="ri-menu-line text-5xl"
        onClick={() => setOpen(true)}
      ></Button>
      <nav
        className={`fixed top-0 flex flex-col bg-darkBlue h-screen w-screen 
          transform duration-300 ease-out ${
            open ? "translate-x-0" : "translate-x-full"
          }`}
      >
        <Button
          className="p-3"
          icon="ri-close-line text-white text-6xl ml-auto"
          onClick={() => setOpen(false)}
        ></Button>
        <ScrollIntoView
          selector="#hero-section"
          className="mx-auto"
          scrollOptions={{ behavior: "smooth", block: "center" }} onClick={goToHome}
        >
          <Button
            className="p-3 text-4xl justify-center text-white"
            onClick={() => setOpen(false)}
          >
            {t("HeaderMenu.home")}
          </Button>
        </ScrollIntoView>
        <ScrollIntoView
          selector="#about-section"
          className="mx-auto"
          scrollOptions={{ behavior: "smooth", block: "end" }} onClick={goToHome}
        >
          <Button
            className="p-3 text-4xl justify-center text-white"
            onClick={() => setOpen(false)}
          >
            {t("HeaderMenu.about")}
          </Button>
        </ScrollIntoView>
        <ScrollIntoView
          selector="#import-export-section"
          className="mx-auto"
          scrollOptions={{ behavior: "smooth", block: "top" }} onClick={goToHome}
        >
          <Button
            className="p-3 text-4xl justify-center text-white"
            onClick={() => setOpen(false)}
          >
            {t("HeaderMenu.services")}
          </Button>
        </ScrollIntoView>
        <ScrollIntoView
          selector="#contact-section"
          className="mx-auto"
          scrollOptions={{ behavior: "smooth", block: "top" }} onClick={goToHome}
        >
          <Button
            className="p-3 text-4xl justify-center text-white"
            onClick={() => setOpen(false)}
          >
            {t("HeaderMenu.contact")}
          </Button>
        </ScrollIntoView>
        <div className="flex flex-grow align-bottom justify-evenly py-3 px-20">
          <Button onClick={() => i18n.changeLanguage("en")} className="mr-2.5">
            <img
              src="/static/Flag_of_Great_Britain.svg"
              alt="En flag"
              className="w-12 h-auto mt-auto"
            />
          </Button>
          <Button
            onClick={() => i18n.changeLanguage("pt-BR")}
            className="mr-2.5"
          >
            <img
              src="/static/Flag_of_Brazil.svg"
              alt="Br flag"
              className="w-12 h-auto mt-auto"
            />
          </Button>
          <Button onClick={() => i18n.changeLanguage("jp")} className="mr-2.5">
            <img
              src="/static/Flag_of_Japan.svg"
              alt="Jp flag"
              className="w-12 h-auto mt-auto"
            />
          </Button>
        </div>
      </nav>
    </div>
  );
}
