import React from "react";

const Error404 = () => {
  return (
    <section className="h-full min-h-[85vh] flex justify-center">
      <div className="m-auto flex flex-col">
        <h1 className="text-8xl mx-auto mb-5 font-bold font-rubik">404</h1>
        <p className="text-3xl max-w-sm font-source-serif text-center">
          Oops, sorry. We couldn't find the page you requested.
        </p>
      </div>
    </section>
  );
};

export default Error404;
