import React from "react";

const Footer = () => {
  return (
    <footer className="w-full mt-28 bg-darkGray">
      <div className="w-full max-w-screen-lg mx-auto flex flex-wrap">
        <div className="px-4 pt-5 pb-2 w-full">
          <img
            src="/static/logo-onoda-boeki-mobile.png "
            alt=""
            className="w-20 h-auto my-10 mx-auto md:ml-1 md:my-0"
          />
        </div>
        <div className="flex flex-col justify-between w-full md:w-1/2">
          <div className="flex text-white px-6 py-2">
            <i className="ri-mail-fill text-gray-400 mr-4"></i>
            <a href="mailto:contact@onodaboeki.co.jp" className="my-auto">contact@onodaboeki.co.jp</a>
          </div>
          <div className="flex text-white px-6 py-2">
            <i className="ri-phone-fill text-gray-400 mr-4"></i>
            <span className="my-auto">+81 050-5539-1762</span>
          </div>
          
          <div className="flex text-white px-6 py-2">
            <i className="ri-map-pin-fill text-gray-400 mr-4"></i>
            <span className="my-auto">
              〒141-0021
              <br />
              Room 803. 2 Gokan, 2-24-11 Kamiosaki,
              <br/>
              Shinagawa-ku, Tokyo, 141-0021, Japan
            </span>
          </div>
          <div className="flex text-white px-6 py-2">
            <i className="ri-map-pin-fill text-gray-400 mr-4"></i>
            <span className="my-auto">
              〒141-0021
              <br />
              東京都品川区上大崎2-24-11
              <br />
              2号館 803号室
            </span>
          </div>
        </div>
        <div className="w-full md:w-1/2 px-4 pt-2">
          <iframe
            title="google-maps"
            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d202.66805051342385!2d139.71443404265364!3d35.63539745320193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x60188b190778ce47%3A0x5326f1452fe676c8!2s2-ch%C5%8Dme-24-11%20Kami%C5%8Dsaki%2C%20Shinagawa%20City%2C%20Tokyo%20141-0021!3m2!1d35.6354605!2d139.7145571!5e0!3m2!1spt-BR!2sjp!4v1647588883089!5m2!1spt-BR!2sjp"
            loading="lazy"
            allowFullScreen=""
            className="border-0 w-full h-full"
          ></iframe>
        </div>
      </div>
      <div className="w-full bg-black text-center text-gray-400 font-source-sans text-xs mt-8">
        Powered by WeDoIT.jp
      </div>
    </footer>
  );
};

export default Footer;
