export function maskPhone(v) {
  let r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 16) {
    r = r.replace(/^(\d\d)(\d\d)(\d{4,5})(\d{5}).*/, "+$1 $2 $3-$4");
  } else if (r.length > 9) {
    r = r.replace(/^(\d\d)(\d\d)(\d{0,5})(\d{4}).*/, "+$1 $2 $3-$4");
  } else if (r.length > 6) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,3}).*/, "+$1 $2 $3");
  } else if (r.length > 3) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "+$1 $2");
  } else {
    r = r.replace(/^(\d*)/, "$1");
  }
  return r;
}

export const maskEmail = (v) => {
  let r = v.replace(/[!#¨$%^&*)(+="|':;\]\[{\/}\\`><?A-Z]+/g, "");
  if(r.includes("@")){
    if(r.match(/@/g).length > 1) {
      return r.slice(0, r.lastIndexOf('@')) +  r.slice(r.lastIndexOf('@') + 1)
    } else {
      return r.replace(/^([A-Za-z0-9._-]\w+[@]+?)(@+?)$/g, "$1")
    }
  } else{
    return r
  }
};
