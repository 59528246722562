import React from "react";
import Error404 from "../components/page/error/404";
import HeaderMenu from "../components/HeaderMenu";
import Footer from "../components/Footer";

const ErrorPage = ({ params }) => {
  return (
    <>
      <HeaderMenu />
      <Error404 />
      <Footer />
    </>
  );
};

export default ErrorPage;
