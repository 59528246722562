import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../commons/Button";
import { maskPhone, maskEmail } from "../../../utils/Masks";
import ContactSchema from "../../../utils/ContactSchema";

const ContactSection = () => {
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState(_form);
  const [loading, setLoading] = useState(false);

  const singleChange = ({ target }) =>
    setForm({ ...form, [target.name]: target.value });
  const phoneChange = ({ target }) =>
    setForm({ ...form, [target.name]: maskPhone(target.value) });
  const emailChange = ({ target }) =>
    setForm({ ...form, [target.name]: maskEmail(target.value) });

  const check = ContactSchema.check(form);
  const errors = Object.values(check).filter((i) => i.hasError);
  const showErrors =
    errors.length > 0 &&
    form.item_contents.length > 0 &&
    form.item_mail_1.length > 0 &&
    form.item_name_1.length > 0;

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    document.getElementById("contact-form").submit();
  };

  const isJp = i18n.language === "jp"

  return (
    <>
      <section
        id="contact-section"
        className={"flex flex-col justify-center sm:justify-end "+(!isJp ? "mt-24" : "mt-0")+" overflow-hidden h-44 md:h-60 lg:h-96 xl:h-92 bg-cover"}
        style={{ 
          backgroundImage: "url('/static/contact-banner.jpg')",
          backgroundPositionY: "40%"
        }}
      >
        <div className="relative w-full max-w-screen-lg md:h-full flex flex-col md:justify-end pb-6 z-10 mx-auto pl-2">
          <h2 className="relative font-source-sans font-bold text-white text-4xl md:text-6xl uppercase whitespace-pre-line md:pl-4">
            {t(`ContactSection.header`)}
          </h2>
        </div>
      </section>
      <section className="bg-white shadow-xl w-full max-w-screen-lg mx-auto md:p-0.5 md:mt-12 flex flex-col">
        <div className="bg-darkBlue p-4 md:px-8 md:py-6 text-white">
          <h4 className="font-rubik text-xl font-semibold tracking-wide">
            {t(`ContactSection.title`)}
          </h4>
          <span className="text-sm font-source-serif font-light tracking-widest">
            {t(`ContactSection.subtitle`)}
          </span>
        </div>
        <form
          method="post"
          action="/script/mailform/website-contact/"
          onSubmit={submit}
          id="contact-form"
        >
          <div className="p-4 md:px-6 md:py-2 flex flex-col font-rubik">
            <label
              htmlFor="name-field"
              className="text-darkGray font-semibold mt-1.5 ml-1"
            >
              {t(`ContactSection.form.name`)}
              <span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              id="name-field"
              name="item_name_1"
              value={form.item_name_1}
              placeholder={t("ContactSection.form.name-placeholder")}
              className="w-full p-1.5 mt-1.5 text-sky-700 border border-neutral-200 rounded focus:ring-1 ring-darkBlue0 ring-offset-0 focus:bg-slate-50 focus:outline-none focus:border-blueIce placeholder:font-thin"
              onChange={singleChange}
              maxLength={50}
            />
            <label
              htmlFor="company-field"
              className="text-darkGray font-semibold mt-1.5 ml-1"
            >
              {t(`ContactSection.form.company`)}
            </label>
            <input
              type="text"
              id="company-field"
              name="item_stext02"
              value={form.item_stext02}
              placeholder={t("ContactSection.form.company-placeholder")}
              className="w-full p-1.5 mt-1.5 text-sky-700 border border-neutral-200 rounded focus:ring-1 ring-darkBlue0 ring-offset-0 focus:bg-slate-50 focus:outline-none focus:border-blueIce placeholder:font-thin"
              onChange={singleChange}
              maxLength={50}
            />
            <label
              htmlFor="phone-field"
              className="text-darkGray font-semibold mt-1.5 ml-1"
            >
              {t(`ContactSection.form.phone`)}
              <span className="text-red-600"> *</span>
            </label>
            <input
              type="text"
              id="phone-field"
              name="item_stext01"
              value={form.item_stext01}
              placeholder={t("ContactSection.form.phone-placeholder")}
              className="w-full p-1.5 mt-1.5 text-sky-700 border border-neutral-200 rounded focus:ring-1 ring-darkBlue0 ring-offset-0 focus:bg-slate-50 focus:outline-none focus:border-blueIce placeholder:font-thin"
              onChange={phoneChange}
            />
            <label
              htmlFor="email-field"
              className="text-darkGray font-semibold mt-1.5 ml-1"
            >
              {t(`ContactSection.form.email`)}
              <span className="text-red-600"> *</span>
            </label>
            <input
              type="email"
              id="email-field"
              name="item_mail_1"
              value={form.item_mail_1}
              placeholder={t("ContactSection.form.email-placeholder")}
              className="w-full p-1.5 mt-1.5 text-sky-700 border border-neutral-200 rounded focus:ring-1 ring-darkBlue0 ring-offset-0 focus:bg-slate-50 focus:outline-none focus:border-blueIce placeholder:font-thin"
              onChange={emailChange}
              maxLength="50"
            />
            <label
              htmlFor="contents-field"
              className="text-darkGray font-semibold mt-1.5 ml-1"
            >
              {t(`ContactSection.form.contents`)}
              <span className="text-red-600"> *</span>
            </label>
            <textarea
              id="contents-field"
              name="item_contents"
              value={form.item_contents}
              placeholder={t("ContactSection.form.contents-placeholder")}
              className="w-full p-1.5 mt-1.5 text-sky-700 border border-neutral-200 rounded focus:ring-1 ring-darkBlue0 ring-offset-0 focus:bg-slate-50 focus:outline-none focus:border-blueIce placeholder:font-thin"
              onChange={singleChange}
              rows={5}
            ></textarea>
            <input
              type="hidden"
              name="submit_button"
              value={form.submit_button}
            />
            <input
              type="hidden"
              name="item_stext03"
              value={i18n.language}
            />
            {showErrors && (
              <div className="border border-neutral-200 rounded mt-3 bg-red-50 bg-opacity-70 p-2 text-sm">
                {errors.map((err, i) => {
                  let splitted = err.errorMessage.split(".");
                  return (
                    <div key={i} className="text-red-600">
                      <b className="mr-2">
                        {t(`ContactSection.form.${splitted[0]}`)}
                      </b>
                      <span>
                        {t(`ContactSection.errors.${err.errorMessage}`)}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
            <Button
              className="w-full p-2 mt-5 mb-4 bg-primary justify-center uppercase text-white rounded cursor-pointer disabled:cursor-not-allowed
              disabled:bg-slate-200 disabled:text-gray-400"
              type="submit"
              disabled={errors.length > 0 || loading}
            >
              {t("ContactSection.form.submit")}
            </Button>
          </div>
        </form>
      </section>
    </>
  );
};

export default ContactSection;

const _form = {
  item_name_1: "", // Name
  item_mail_1: "", // Email
  item_contents: "", // Message
  item_stext01: "", // Phone
  tem_stext02: "", // Company
  item_stext03: "en",
  submit_button: "送信", // Required to send the email

};
