import React, {useState} from 'react';

const KaishaAnnai = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <section
        id="kaisha-annai-section"
        className="mt-28 bg-center bg-cover h-32 md:h-32 flex flex-col justify-center sm:justify-end bg-darkBlue"
      >
        <div className="max-w-screen-lg w-full m-auto md:h-full flex justify-between items-center px-2">
          <h2 className="font-source-sans font-bold text-white text-4xl text-center md:text-left uppercase whitespace-pre-line md:pl-4">
            会社案内
          </h2>
          <button 
            className="bg-darkGray border border-white py-2 px-4 text-xl w-32 md:w-44  text-white"
            onClick={() => setExpanded(!expanded)}
          >
            {!expanded ? "開く":"閉じる"}
          </button>
        </div>
      </section>
      <section 
        className={`relative w-full max-w-screen-lg font-rubik mx-auto flex flex-col overflow-scroll sm:overflow-hidden transition-all duration-500 ${expanded 
          ? "max-h-full h-[92vh] my-10 md:my-24"
          : "max-h-0 h-0 "}`
      }>
        <h3 className="w-full border-0 md:border-l-8 border-darkBlue text-4xl font-semibold tracking-tight px-2 sm:px-0 text-center md:text-left">
          <span className="sm:ml-10 md:ml-16 uppercase">
            会社概要
          </span>
        </h3>
        <div className="px-4 mt-6 md:pl-16 md:ml-2 md:mr-6 text-left leading-7 text-darkGray">
          <table id="kaisha-annai-table" className='w-full table-auto'>
            <tr>
              <th>商号</th>
              <td>株式会社　オノダ貿易</td>
            </tr>
            <tr>
              <th>英文</th>
              <td>Onoda Boeki　Ｃｏ., Ｌｔｄ．</td>
            </tr>
            <tr>
              <th className='pt-4'>事業内容</th>
              <td className='pt-4'>貿易事業（南米 ブラジル）</td>
            </tr>
            <tr>
              <th></th>
              <td className='flex flex-wrap'>
                <span className='w-1/2'>■　家庭用品</span>
                <span className='w-1/2'>■　雑貨品</span>
                <span className='w-1/2'>■　文具</span>
                <span className='w-1/2'>■　事務用品</span>
                <span className='w-1/2'>■　食品</span>
                <span className='w-1/2'>■　飲料</span>
              </td>
            </tr>
            <tr>
              <th className='pt-4'>代表者</th>
              <td className='pt-4'>代表取締役　宮川　礼昭ダウト</td>
            </tr>
            <tr>
              <th></th>
              <td>代表取締役　宮川　昭</td>
            </tr>
            <tr>
              <th className='pt-4'>設立</th>
              <td className='pt-4'>令和３年１０月０１日</td>
            </tr>
            <tr>
              <th className='pt-4'>資本金</th>
              <td className='pt-4'>1,500,000円</td>
            </tr>
            <tr>
              <th className='pt-4 whitespace-nowrap'>取引銀行</th>
              <td className='pt-4'>三菱UFJ銀行（三田支店）</td>
            </tr>
            <tr>
              <th></th>
              <td>みずほ銀行（大森支店）</td>
            </tr>
            <tr>
              <th className='pt-4'>所在地</th>
              <td className='pt-4'>本社</td>
            </tr>
            <tr>
              <th></th>
              <td className='pl-4'>〒141-0021</td>
            </tr>
            <tr>
              <th></th>
              <td className='pl-4'>高輪交陽ハイツ1101</td>
            </tr>
            <tr>
              <th></th>
              <td className='pl-4'>東京都品川区上大崎2-24-11</td>
            </tr>
            <tr>
              <th></th>
              <td className='pl-4'>目黒西口マンション2号館803号室</td>
            </tr>
            <tr>
              <th className='pt-4'>Tel</th>
              <td className='pt-4'>050-5539-1762</td>
            </tr>
            <tr>
              <th className='pt-4'>携帯</th>
              <td className='pt-4'>080-4881-8947</td>
            </tr>
          </table>
        </div>
      </section>
    </>
  );
}

export default KaishaAnnai;